:root {
  --mainDark: #343a40;
  --mainGrey: rgba(172, 172, 172);
  --mainLightGrey: rgba(172,172,172);
  --mainWhite: #fff;
  --mainBlue: #007bff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-two{

   background-size:1000px;
    background-position: center; 
  background-size: cover;
padding-bottom:600px;
}

.home-title{
  padding-top:17%;
  text-align: center;
  color:white;
}
.home-title-heading{
  text-align: center;
  color:white;
}
.home-pic{
  background-image: url('Lamppost2.png');
   background-size:1000px;
    background-position: center; 
  background-size: cover;
  padding-bottom:600px;
}
.list-unstyled {
  display: flex;
  flex-direction: column; /* Add this line for vertical stacking */
  justify-content: center;
  align-items: center; /* Optional: Center items vertically */
}
.nameHome{
  display: flex;
  justify-content: center;
  margin-left: 25%;
  margin-top: 10%;
}


.player-wrapper{
  display: flex;
  justify-content: center;
  padding-bottom:100px;
  overflow: auto;
}

.footer-middle {
   background: var(--mainDark);
    color: var(--mainWhite);
}

.main-footer{
position:fixed;
bottom:0;
width: 100%;
}
.footer-row{
  margin: 0 auto;
  display:flex;
  justify-content: center;
}
ul li a{
    color: var(--black);
}

ul li a:hover{
    color: var( --mainBlue);
}

ul.list-unstyled {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  padding-left: 0;
  list-style: none;
  text-align: center; /* Center text within items */
}

ReactPlayer {

}

.navbar{
    top:0;
    position: fixed;
    z-index:100;
    width:100%;
}


.dropdown-item {
  background-color: #343a40; /* Dark grey background */
  color: #fff; /* White text */
}

.dropdown-item:hover {
  background-color: #495057; /* Slightly lighter grey on hover */
  color: #fff; /* White text on hover */
}

.navbar-togger:focus {
  outline: none;
  margin-top:100px;
  margin-bottom:50px;

}

.navbar-togger {
  border: none;
  margin-top:100px;
  margin-bottom:50px;
}

* {
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

html, body {
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.grid-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  overflow: hidden; /* Prevent overflow */
}

.grid-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
  max-width: 800px; /* Limit width on larger screens */
  width: 100%; /* Make sure it takes full width */
  margin: 0 auto; /* Center the grid container */
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-img {
  max-width: 100%; /* Ensure images are responsive */
  height: auto; /* Maintain aspect ratio */
}

.grid-item:hover .grid-img {
  transform: scale(1.05); /* Scale up on hover */
  transition: transform 0.3s ease; /* Smooth transition */
}

/* Media queries for responsive adjustments */
@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust for smaller screens */
  }
}



.illustrations{
  margin:0 auto;
}
.AboutText{
 
  margin: 0 auto;
text-align: left;
}

.carousel {
  /* display: flex;
  justify-content: center; */
  padding-bottom:30px;
  overflow:auto;
}

.carousel-outer .carousel .slide {
    background: none;
}

.fixed_img {
  max-width:30em;  /* change this to whatever you want */
    height: auto;
    margin-top: 2%;
    padding-bottom:100px;
}



.fixed_img_graphic_design{
  max-width: 50em;  /* change this to whatever you want */
    height: auto;
    margin-top: 2%;
    margin-bottom: -10%;
    padding-bottom:100px;
}
.fixed_img_three_d{
  max-width: 60em;  /* change this to whatever you want */
    height: auto;
  max-height: 20em;
    margin-top: 2%;
    margin-bottom: 5%;
}
@media only screen and (max-width: 600px) {
.fixed_img {
max-width:20em;
}
.fixed_img_graphic_design{
  max-width:20em;
}
.fixed_img_three_d{
  max-width: 20em;
}
}
  
.Name {
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.AboutParagraph{
  display: flex;
  justify-content: center;
  margin-left:23%;
  margin-right:23%;
 padding-top:3%;
  font-size: 1.3em;
  font-weight:bold;
  line-height:normal;
  color: white;
  
}

.About{
  margin: 0 auto;
  display: flex;
  justify-content: center;
  color: white;
  padding-bottom:20%;
}
.graphic-design{
  padding-bottom:20%;
}
.SkillsAndSoftware{
  margin: 0 auto;
  margin-left: 10%;
  margin-right: 40%;
  padding-top:130px;
  display: flex;
  flex-wrap: wrap;
  color: white; 
}

@media only screen and (max-width: 990px) {
  .SkillsAndSoftware{
    padding-top:200px;
  }
  .About{
    padding-top:70%;
  }
}
.certifications{
  color:white;
}
h2{
  padding-top:1em;
}
.SkillsTitle{
  text-decoration: underline;
  color:white;
  font-size: 3em;
}
.SkillsParagraphText{
  font-size:1em;
  font-weight:bold;
  color:white;
}

.SoftwareTitle{
  color:white;
  text-decoration: underline;
  font-size: 3em;
}
.SoftwareParagraphText {
  color:white;
  font-size:1em;
  font-weight:bold;

}

.webDevWrap{
padding-top:100px;
}


.webDevLinks{
  color:white;
  text-shadow: 1px white;
  margin: 0 auto;
  display: flex;
  justify-content: center;

}

@media only screen and (max-width: 800px) {
  
.webDevLinks{
  padding-top:25%;
}
  }

@media only screen and (max-width: 600px) {
.webDevLinks{
  padding-top:35%;
  
}
  }


  .LinkZero, .LinkOne, .LinkTwo, .LinkThree, .LinkFour, .LinkFive{
  margin: 0 auto;
  margin-top: 5%;
  font-weight:bold;
  font-size:1.5em;
  color:white;
}


.contact-info{
  margin: 0 auto;
  color:rgb(131, 197, 228);
  padding-bottom: 1em;
  margin-top: -5em;
  text-align: center;
  font-size:1em;
  list-style-type: none;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.animation-window{

 padding-top:100px;
 padding-bottom:200px;
}

.animation-videos{
  padding-top:3%;
   padding-bottom:3%;
  
}

.animation-video-each{
  padding-bottom: 100px;
   border: '10px solid black';
}
.player-wrapper{
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-left: 25%;
  
}

.audio-reel{
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom:250px;
  padding-top:100px;
 background: none;
}

.video-description {
  color: white;
  font-size: 24px;
}
.TranscriptsSection {
    max-width: 400px; /* Set a max width for the transcripts section */
    float:left;
    padding-left:10em;
    
}

.TranscriptsSection ul {
    list-style-type: none; /* Remove bullet points */
   /* Remove default padding */
}

.TranscriptsSection a {
    text-decoration: none; /* Remove underline */
    color: white; /* Change link color */
}